import React from "react";
import Layout from "../components/Layout";

export default function Four04() {
  return (
    <Layout className="error" title="Ooops!">
      <h1>404!</h1>
    </Layout>
  );
}
